import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Адреналин Арена
			</title>
			<meta name={"description"} content={"Отправляйтесь в незабываемое происшествие на Адреналин Арена Paintball, где азарт встречается со стратегией и командной работой."} />
			<meta property={"og:title"} content={"Адреналин Арена"} />
			<meta property={"og:description"} content={"Отправляйтесь в незабываемое происшествие на Адреналин Арена Paintball, где азарт встречается со стратегией и командной работой."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3">
				Адреналин Арена
			</Override>
		</Components.Header>
		<Components.Marque />
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						max-height="500px"
						srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					width="50%"
					padding="0px 80px 0px 0"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					order="-1"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Какие услуги мы оказываем?
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						В Адреналин Арена Paintball мы гордимся тем, что предлагаем разнообразные пейнтбольные услуги, разработанные для обеспечения увлекательного опыта для каждого игрока. Независимо от того, хотите ли вы отточить свои навыки, отпраздновать особое событие или просто повеселиться с друзьями, наши заведения созданы для того, чтобы обеспечить незабываемый и увлекательный день.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="100%" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09:05:26.723Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/0630624ea8d301c9.jpg?v=2024-06-13T09%3A05%3A26.723Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					Открытые игровые сеансы: погрузитесь в действие с помощью наших сеансов игры. Идеально подходит для отдельных лиц или групп, желающих сочетаться с другими игроками. Уровни смешанных навыков: отлично подходит как для новичков, так и для опытных игроков. Гибкое время: присоединяйтесь, когда вам удобно, с занятиями, которые продлятся в течение дня.
					<br />
					<br />
					{"\n\n\n"}Молодежные и школьные мероприятия: безопасные, веселые и контролируемые мероприятия, предназначенные для младших игроков. Соответствующее возрасту оборудование: более легкое снаряжение и меньшее влияние для младших игроков. Образовательный фокус: упор на командную работу, лидерство и спортивное мастерство.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						Частные групповые бронирования: бронируйте игровое время для вечеринок, командообразования или частных матчей. Личные рефери: каждая группа получает специального сотрудника, управляющего игрой. Настраиваемые сценарии: настройте режим игры в соответствии с предпочтениями вашей группы.
						<br />
						<br />
						{"\n\n\n"}Тактические тренировки: усовершенствуйте свои навыки под руководством наших опытных инструкторов. Развитие навыков: Сосредоточьтесь на стратегии, точности стрельбы и командной работе. Расширенные методы: изучите тактику, дающую вам конкурентное преимущество.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09:05:26.728Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="100%" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09:05:26.765Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball.webp?v=2024-06-13T09%3A05%3A26.765Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					Специальные события и турниры: принимайте участие в наших увлекательных турнирах или присоединяйтесь к специальным тематическим мероприятиям. Соревновательная игра: идеально подходит для тех, кто хочет проверить свои навыки против лучших. Уникальные темы: Регулярно планируемые события с уникальным игровым процессом и призами.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
						</Em>
						<Strong>
							Больше, чем просто игры{"\n"}
							<br />
						</Strong>
						<br />
						{"\n"}Ажиотаж в Адреналин Арена не ограничивается только играми. Мы предлагаем полный спектр услуг, чтобы улучшить ваш опыт пейнтбола. Мы здесь, чтобы сделать ваши пейнтбольные приключения максимально приятными и доступными: от современного снаряжения напрокат до советов экспертов и радушного сообщества.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09:05:26.740Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});